import React from 'react';
import Components from '../components/Components.js';

class StoryblokEntry extends React.Component {
	static getDerivedStateFromProps(props, state) {
		if (state.story.uuid === props.pageContext.story.uuid) {
			return null;
		}

		return StoryblokEntry.prepareStory(props);
	}

	static prepareStory(props) {
		const story = Object.assign({}, props.pageContext.story);
		const siblings = Object.assign({}, props.pageContext.siblings);
		const nav = Object.assign({}, props.pageContext.navigation);
		const metadata = Object.assign({}, props.pageContext.metadata);
		story.content = JSON.parse(story.content);

		typeof window !== `undefined` && window.localStorage.setItem(`lang`, nav.lang);
		return { story, siblings, nav, metadata};
	}

	constructor(props) {
		super(props);
		this.state = StoryblokEntry.prepareStory(props);
	}

	render() {
		const {story, siblings, nav, metadata} = this.state;
		const {content, full_slug } = story;
		return (
			<div className={`entry-wrapper site-content ${content.classes} ${story.field_component}-${story.slug}`}>
				{
					React.createElement(
						Components(content.component), 
						{
							key: content._uid, 
							blok: content, 
							slug: full_slug, 
							siblings, 
							nav,
							metadata,
							lang: nav.lang
						}
					)
				}
			</div>
		);
	}
}

export default StoryblokEntry;